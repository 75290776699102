import React, { useState } from 'react'
import { IntlProvider } from 'react-intl'

import messagesCn from '../messages/cn.json'
import messagesDe from '../messages/de.json'
import messagesEn from '../messages/en.json'
import messagesEs from '../messages/es.json'
import messagesFr from '../messages/fr.json'
import messagesIt from '../messages/it.json'
import messagesRu from '../messages/ru.json'
import messagesSq from '../messages/sq.json'

const STORAGE_KEY = 'catchup_selected_language'

const DEFAULT_LOCALE = 'sq'

const MESSAGES = {
  cn: messagesCn,
  de: messagesDe,
  en: messagesEn,
  es: messagesEs,
  fr: messagesFr,
  it: messagesIt,
  ru: messagesRu,
  sq: messagesSq
}

export const LANGUAGES = [
  { code: 'cn', name: '中文' },
  { code: 'de', name: 'Deutsche' },
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Española' },
  { code: 'fr', name: 'Français' },
  { code: 'it', name: 'Italiano' },
  { code: 'ru', name: 'Русский' },
  { code: 'sq', name: 'Shqip' }
]

const selectedLocale = localStorage.getItem(STORAGE_KEY)
const defaultLocale = selectedLocale ? selectedLocale : DEFAULT_LOCALE

export const TranslationContext = React.createContext({})

export default function TranslationProvider({ children }) {
  const [locale, setLocale] = useState(defaultLocale)

  const switchLocale = newLocale => {
    if (!MESSAGES[newLocale]) {
      return
    }
    localStorage.setItem(STORAGE_KEY, newLocale)
    setLocale(newLocale)
  }

  return (
    <TranslationContext.Provider
      value={{
        locale,
        switchLocale
      }}
    >
      <IntlProvider locale={locale} messages={MESSAGES[locale]}>
        {children}
      </IntlProvider>
    </TranslationContext.Provider>
  )
}
