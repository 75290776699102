import React from 'react'

export const Auth0 = React.createContext(null)

export function withAuth0WebAuth(Component) {
  return props => (
    <Auth0.Consumer>
      {webAuth => <>{webAuth !== null && <Component webAuth={webAuth} {...props} />}</>}
    </Auth0.Consumer>
  )
}
