export const configs = () => {
  return {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('auth_jwt_token')
    }
  }
}

export const configs1 = () => {
  return {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('auth_jwt_token'),
      'Content-Type': 'multipart/form-data'
    }
  }
}
