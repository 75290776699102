import React, { useEffect } from 'react'
import { connect } from 'react-redux'

export default function(ComposedComponent) {
  function WithAuthentication(props) {
    const { authenticated, history } = props

    useEffect(() => {
      if (authenticated === false) {
        history.push('/login')
      }
      // eslint-disable-next-line
    }, [authenticated])

    return authenticated !== null && <ComposedComponent {...props} />
  }

  function mapStateToProps({ auth }) {
    return { authenticated: auth.authenticated }
  }

  return connect(mapStateToProps)(WithAuthentication)
}
