import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import { connect } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import config from '../../api/config'
import { withAuth0WebAuth } from '../../context/auth0'
import signupImg from './../../assets/images/signup.png'
import { ToastSuccess, ToastError } from '../../actions/toaster'
import { signUserIn } from '../../actions/auth'

function Register(props) {
  const { status, history, webAuth } = props
  const [fakeCount, forceUpdate] = useState(0)
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    password: ''
  })
  const v = useRef(new SimpleReactValidator())
  const intl = useIntl()

  const loginErrorMsg = {
    invalid_request: intl.formatMessage({
      id: 'login.invalid_request',
      defaultMessage: 'Email and password are required.'
    }),
    access_denied: intl.formatMessage({
      id: 'login.access_denied',
      defaultMessage: 'Wrong email or password.'
    })
  }

  const registerErrorMsg = {
    invalid_signup: intl.formatMessage({
      id: 'register.invalid_signup',
      defaultMessage: 'Email already used.'
    }),
    invalid_password: intl.formatMessage({
      id: 'register.invalid_password',
      defaultMessage: 'Password too weak: at least 6 characters in length.'
    })
  }

  function setStateFromInput(event) {
    const newFormState = {
      ...formState,
      [event.target.name]: event.target.value
    }
    setFormState(newFormState)
  }

  function facebookLogin() {
    webAuth.popup.authorize(
      {
        connection: 'facebook',
        responseType: 'token id_token',
        owp: true
      },
      handleAuth0Login
    )
  }

  function googleLogin() {
    webAuth.popup.authorize(
      {
        connection: 'google-oauth2',
        responseType: 'token',
        owp: true
      },
      handleAuth0Login
    )
  }

  function handleAuth0Login(err, authResult) {
    if (err == null) {
      const { accessToken } = authResult
      webAuth.client.userInfo(accessToken, (err, user) => {
        const { name, email } = user
        signUserIn({
          name,
          email,
          token: accessToken
        })
      })
    } else {
      const { error } = err
      ToastError(
        loginErrorMsg[error] ||
          intl.formatMessage({ id: 'login.failed', defaultMessage: 'Cannot login' })
      )
      console.error(err)
    }
  }

  function handleSubmit(event) {
    event.preventDefault()
    if (v.current.allValid()) {
      const { name, email, password } = formState
      webAuth.signup(
        {
          connection: config.auth0realm,
          email,
          password,
          name
        },
        err => {
          if (err == null) {
            ToastSuccess(
              intl.formatMessage({
                id: 'register.success',
                defaultMessage: 'Signed up successful.'
              })
            )
            history.push('/login')
          } else {
            const { code } = err
            ToastError(
              registerErrorMsg[code] ||
                intl.formatMessage({ id: 'register.failed', defaultMessage: 'Cannot register.' })
            )
            console.error(err)
          }
        }
      )
    } else {
      v.current.showMessages()
      forceUpdate(fakeCount + 1)
    }
  }

  function goBack() {
    history.goBack()
  }

  useEffect(() => {
    if (status !== null && status.status === 'success') history.push('/login')
    // eslint-disable-next-line
  }, [status])

  return (
    <div>
      <div className="container wrapper-container">
        <div className="row">
          <div className="col-md-6 signup-bg d-none d-md-flex align-items-center">
            <div className="signup-detail">
              <img src={signupImg} className="img-fluid" alt="signup" />
            </div>
          </div>
          <div className="col-md-6 signup-form d-flex align-items-center">
            <div className="signup-here">
              <form className="" onSubmit={handleSubmit}>
                <h2 className="mb-5">
                  <i
                    onClick={goBack}
                    className="fas fa-arrow-left d-inline-block d-md-none mr-3"
                  ></i>
                  <FormattedMessage id="register.title" defaultMessage="Sign Up here" />
                </h2>
                <div className="form-group">
                  <label>
                    <FormattedMessage id="common.full_name" defaultMessage="Full Name" />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    onChange={setStateFromInput}
                    value={formState.name}
                  />
                  {v.current.message('name', formState.name, 'required')}
                </div>
                <div className="form-group">
                  <label>
                    <FormattedMessage id="common.email" defaultMessage="Email" />
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    onChange={setStateFromInput}
                    value={formState.email}
                  />
                  {v.current.message('email', formState.email, 'required|email')}
                </div>
                <div className="form-group">
                  <label>
                    <FormattedMessage id="common.password" defaultMessage="Password" />
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    onChange={setStateFromInput}
                    value={formState.password}
                  />
                  {v.current.message('password', formState.password, 'required')}
                </div>
                <button type="submit" className="btn btn-danger btn-bg btn-block mt-5 mb-4">
                  <FormattedMessage id="register.next" defaultMessage="NEXT" />
                </button>
                <button
                  type="button"
                  className="btn btn-login btn-facebook btn-bg btn-block mt-5 mb-4"
                  onClick={facebookLogin}
                >
                  <FormattedMessage
                    id="login.login_facebook"
                    defaultMessage="Login With Facebook"
                  />
                </button>
                <button
                  type="button"
                  className="btn btn-login btn-google btn-bg btn-block mt-5 mb-4"
                  onClick={googleLogin}
                >
                  <FormattedMessage id="login.login_google" defaultMessage="Login With Google" />
                </button>
                <p className="text-center">
                  <FormattedMessage
                    id="common.login_call"
                    defaultMessage="Already have an account?"
                  />{' '}
                  <Link to="/login">
                    <FormattedMessage id="common.login_link" defaultMessage="Log In Here" />
                  </Link>
                </p>
                <p className="text-center">
                  <Link to="/home">
                    <FormattedMessage id="common.home" defaultMessage="Return Home" />
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function withRedux(Component) {
  return connect(
    ({ auth, user }) => ({
      errorMsg: auth.error,
      status: user.status
    }),
    null
  )(Component)
}

const EnhancedRegister = withAuth0WebAuth(withRedux(Register))

export default EnhancedRegister
