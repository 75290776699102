import React from 'react'

function GoogleMapMaker(props) {
  const { focused, icon, name } = props
  return (
    <div className={`custom-marker ${focused ? 'custom-marker-active' : ''}`}>
      <img src={icon} alt={name} className="marker-pin" />
    </div>
  )
}

export default GoogleMapMaker
