import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form, Input } from 'reactstrap'
import { FormattedMessage, useIntl } from 'react-intl'

import config from '../../api/config'
import { withAuth0WebAuth } from '../../context/auth0'
import ControlledCarousel from '../Widgets/ControlledCarousel'
import { signUserIn } from '../../actions/auth'
import { ToastSuccess, ToastError } from '../../actions/toaster'

function Login(props) {
  const { history, signUserIn, authenticated, webAuth, errorMsg } = props
  const intl = useIntl()

  const [formState, setFormState] = useState({
    email: '',
    password: ''
  })

  const loginErrorMsg = {
    invalid_request: intl.formatMessage({
      id: 'login.invalid_request',
      defaultMessage: 'Email and password are required.'
    }),
    access_denied: intl.formatMessage({
      id: 'login.access_denied',
      defaultMessage: 'Wrong email or password.'
    })
  }

  function handleSubmit(e) {
    e.preventDefault()
    // signUserIn(formState)
    const { email, password } = formState
    webAuth.login(
      {
        email,
        password,
        realm: config.auth0realm,
        responseType: 'token id_token',
        popup: true
      },
      handleAuth0Login
    )
  }

  function facebookLogin() {
    webAuth.popup.authorize(
      {
        connection: 'facebook',
        responseType: 'token id_token',
        owp: true
      },
      handleAuth0Login
    )
  }

  function googleLogin() {
    webAuth.popup.authorize(
      {
        connection: 'google-oauth2',
        responseType: 'token',
        owp: true
      },
      handleAuth0Login
    )
  }

  function handleAuth0Login(err, authResult) {
    if (err == null) {
      const { accessToken } = authResult
      webAuth.client.userInfo(accessToken, (err, user) => {
        const { name, email } = user
        signUserIn({
          name,
          email,
          token: accessToken
        })
      })
    } else {
      const { error } = err
      ToastError(
        loginErrorMsg[error] ||
          intl.formatMessage({ id: 'login.failed', defaultMessage: 'Cannot login' })
      )
      console.error(err)
    }
  }

  function moveToForgetPassword(e) {
    e.preventDefault()
    history.push('/forgot')
  }

  function setStateFromInput(event) {
    const newFormState = {
      ...formState,
      [event.target.name]: event.target.value
    }
    setFormState(newFormState)
  }

  function goBack() {
    history.goBack()
  }

  useEffect(() => {
    if (authenticated) {
      ToastSuccess(
        intl.formatMessage({
          id: 'login.success',
          defaultMessage: 'Success! You are Successfully Login'
        })
      )
      history.push('/')
    }
    // eslint-disable-next-line
  }, [authenticated])

  return (
    <div className="container wrapper-container">
      <div className="row">
        <div className="col-md-6 signup-bg login-img d-none d-md-flex align-items-center">
          <div className="signup-detail login-slider">
            <h1>
              <FormattedMessage id="login.image_title" defaultMessage="Ease of use" />
            </h1>
            <ControlledCarousel />
          </div>
        </div>
        <div className="col-md-6 signup-form d-flex align-items-center">
          <div className="signup-here login-here">
            <Form className="" onSubmit={handleSubmit}>
              <h2 className="mb-5">
                <i onClick={goBack} className="fas fa-arrow-left d-inline-block d-md-none mr-3"></i>
                <FormattedMessage id="login.welcome" defaultMessage="Hi, Welcome!" />
              </h2>
              {errorMsg && <div className="text-danger mb-3">{errorMsg}</div>}
              <div className="form-group">
                <label>
                  <FormattedMessage id="common.email" defaultMessage="Email" />
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formState.email}
                  onChange={setStateFromInput}
                />
              </div>
              <div className="form-group">
                <label>
                  <FormattedMessage id="common.password" defaultMessage="Password" />
                </label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={formState.password}
                  onChange={setStateFromInput}
                />
              </div>
              <div className="text-right">
                <a onClick={moveToForgetPassword} href="#/">
                  <FormattedMessage
                    id="login.forgot_password"
                    defaultMessage="Forget your password?"
                  />
                </a>
              </div>

              <button type="submit" className="btn btn-login btn-danger btn-bg btn-block mt-5 mb-4">
                <FormattedMessage id="login.login_email" defaultMessage="Login With Email" />
              </button>
              <button
                type="button"
                className="btn btn-login btn-facebook btn-bg btn-block mt-5 mb-4"
                onClick={facebookLogin}
              >
                <FormattedMessage id="login.login_facebook" defaultMessage="Login With Facebook" />
              </button>
              <button
                type="button"
                className="btn btn-login btn-google btn-bg btn-block mt-5 mb-4"
                onClick={googleLogin}
              >
                <FormattedMessage id="login.login_google" defaultMessage="Login With Google" />
              </button>
              <p className="text-center mt-5">
                <FormattedMessage
                  id="common.register_call"
                  defaultMessage="Don't have an account?"
                />{' '}
                <Link to="/register">
                  <FormattedMessage id="common.register_link" defaultMessage="Sign Up Here" />
                </Link>
              </p>
              <p className="text-center">
                <Link to="/home">
                  <FormattedMessage id="common.home" defaultMessage="Return Home" />
                </Link>
              </p>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

function withRedux(Component) {
  return connect(
    ({ auth }) => ({
      errorMsg: auth.error,
      authenticated: auth.authenticated
    }),
    { signUserIn }
  )(Component)
}

const EnhancedLogin = withAuth0WebAuth(withRedux(Login))

export default EnhancedLogin
