import React from 'react'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'

import ProfileBox from './ProfileBox'
import GoogleAutocomplete from './GoogleAutocomplete'

function DefaultAside(props) {
  const {
    toggleSidebar,
    sideOpen,
    triggerSearch,
    keyword,
    setKeyword,
    profileKindFilter,
    setProfileKindFilter,
    profiles,
    hoveredProfile,
    onItemClick,
    onItemMouseIn,
    onItemMouseOut,
    setPosition,
    authenticated,
    selectedProfile,
    setDefaultZoom,
    setBoundsFromAutocomplete,
    address,
    setAddress
  } = props

  function changeKeyword(e) {
    const { value } = e.target
    setKeyword(value)
  }

  function toggleUser() {
    setProfileKindFilter({
      ...profileKindFilter,
      user: !profileKindFilter.user
    })
  }

  function toggleCompany() {
    setProfileKindFilter({
      ...profileKindFilter,
      company: !profileKindFilter.company
    })
  }

  function onFormSent(e) {
    e.preventDefault()
    triggerSearch()
  }

  function onPositionChange(change) {
    const { latitude, longitude, bounds, types } = change
    setPosition({ latitude, longitude })
    if (types.some(t => t === 'street_address')) {
      setDefaultZoom()
      setBoundsFromAutocomplete(undefined)
    } else {
      setBoundsFromAutocomplete(bounds)
    }
  }

  return (
    <aside
      className={`aside-company ${optionalClassname(sideOpen, 'aside-fold')} ${optionalClassname(
        authenticated,
        'aside-signup'
      )}`}
    >
      <div className="d-flex flex-column h-100">
        <i className="fa fa-search text-white aside-show" onClick={toggleSidebar}></i>
        <div className="aside-content">
          <form onSubmit={onFormSent}>
            <div className="d-flex flex-row align-items-center">
              <i className="fa fa-map-marker-alt fa-fw text-white mr-3"></i>
              <div className="flex-fill">
                <GoogleAutocomplete
                  setPosition={onPositionChange}
                  address={address}
                  setAddress={setAddress}
                />
              </div>
            </div>
            <div className="d-flex flex-row align-items-center">
              <i className="fa fa-search fa-fw text-white mr-3"></i>
              <div className="search-input flex-fill">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Keywords…"
                  value={keyword}
                  onChange={changeKeyword}
                />
              </div>
            </div>
            <div className="search-btn w-100 mt-1 mb-3">
              <button type="submit" className="w-100">
                Search
              </button>
            </div>
          </form>
          <div className="mb-1 mt-1 d-flex">
            <div className="form-group">
              <input
                type="checkbox"
                id="Individual"
                onChange={toggleUser}
                checked={profileKindFilter.user}
              />
              <label htmlFor="Individual">
                <span>
                  <FormattedMessage id="search.filter_individual" defaultMessage="Individual" />
                </span>
              </label>
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                id="company"
                onChange={toggleCompany}
                checked={profileKindFilter.company}
              />
              <label htmlFor="company">
                <span>
                  <FormattedMessage id="search.filter_company" defaultMessage="Company" />
                </span>
              </label>
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className="search_results">
            {profiles.map(item => {
              const hovered =
                hoveredProfile &&
                (hoveredProfile.companyid === item.companyid &&
                  hoveredProfile.__type === item.__type)
              const selected =
                selectedProfile &&
                (selectedProfile.companyid === item.companyid &&
                  selectedProfile.__type === item.__type)

              return (
                <ProfileBox
                  item={item}
                  key={`${item.companyid}_${item.__type}`}
                  hovered={hovered || selected}
                  selectMarker={onItemClick}
                  highlightMarker={onItemMouseIn}
                  clearHighlighted={onItemMouseOut}
                />
              )
            })}
          </div>
        )}
      </div>
      <div className="toggle-sidebar">
        <button onClick={toggleSidebar} className="d-none d-md-block">
          <i className="fa fa-chevron-left"></i>
        </button>
        <button onClick={toggleSidebar} className="d-block d-md-none">
          <i className="fa fa-chevron-up"></i>
        </button>
      </div>
    </aside>
  )
}

function optionalClassname(apply, name) {
  return apply ? '' : `${name} `
}

export default DefaultAside
