import React, { useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import * as auth0 from 'auth0-js'
import config from './api/config'

import { store } from './store'
import './assets/css/style.css'
import './assets/css/home.css'
import './assets/css/layout.css'

import { Auth0 } from './context/auth0'
import AuthGuard from './components/AuthGuard'
import TranslationProvider from './components/TranslationProvider'
import { Login, ForgotPassword, Register, DefaultLayout, Signin } from './views/pages'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  const [webAuth, setWebAuth] = useState(null)

  React.useEffect(() => {
    const webAuth = new auth0.WebAuth({
      domain: config.auth0Domain,
      clientID: config.auth0ClientID,
      responseType: 'token',
      redirectUri: config.appUrl,
      audience: config.auth0Audience,
      scope: 'openid profile email'
    })

    setWebAuth(webAuth)
  }, [])

  return (
    <div>
      <ToastContainer />
      <Auth0.Provider value={webAuth}>
        <Provider store={store}>
          <TranslationProvider>
            <AuthGuard>
              <BrowserRouter>
                <Switch>
                  <Route exact path="/signin" name="Signin Page" component={Signin} />
                  <Route exact path="/login" name="Login Page" component={Login} />
                  <Route exact path="/forgot" name="Forgot Page" component={ForgotPassword} />
                  <Route exact path="/register" name="Register Page" component={Register} />
                  <Route path="/" name="Home" component={DefaultLayout} />
                </Switch>
              </BrowserRouter>
            </AuthGuard>
          </TranslationProvider>
        </Provider>
      </Auth0.Provider>
    </div>
  )
}

export default App
