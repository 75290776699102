import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'

import forgetpass from './../../assets/images/forgetpass.png'
import cross from './../../../src/assets/images/cross_black.png'
import { withAuth0WebAuth } from '../../context/auth0'
import { ToastInfo, ToastError } from '../../actions/toaster'
import config from '../../api/config'

function ForgotPassword(props) {
  const { webAuth, history } = props
  const [formState, setFormState] = useState({ email: '' })
  const intl = useIntl()

  function handleSubmit(e) {
    e.preventDefault()
    const { email } = formState

    webAuth.changePassword(
      {
        connection: config.auth0realm,
        email
      },
      (err, response) => {
        if (err == null) {
          ToastInfo(
            intl.formatMessage({
              id: 'forgot.sent',
              defaultMessage: 'Recovery email has been sent.'
            })
          )
          history.push('/login')
        } else {
          ToastError(
            intl.formatMessage({
              id: 'forgot.failed',
              defaultMessage: 'Cannot send recovery email'
            })
          )
          console.error(err)
        }
      }
    )
  }

  function setInputTagonChange(event) {
    const newFormState = {
      ...formState,
      [event.target.name]: event.target.value
    }
    setFormState(newFormState)
  }

  return (
    <div>
      <div className="container wrapper-container">
        <div className="row">
          <div className="col-md-6 signup-bg d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <div className="signup-detail">
              <img src={forgetpass} className="img-fluid cross_img" alt="forgetpass" />
            </div>
          </div>
          <div className="col-md-6 signup-form">
            <div
              className="d-block d-sm-block d-md-none d-lg-none d-xl-none"
              style={{ marginTop: '40px', fontSize: '24px' }}
            >
              <img src={cross} className="img-fluid cross_img" alt="cross" />
            </div>
            <div className="signup-here">
              <form onSubmit={handleSubmit}>
                <h2 className="mb-5">
                  <FormattedMessage id="forgot.title" defaultMessage="Forgot password" />
                </h2>
                <p className="mb-4">
                  <FormattedMessage
                    id="forgot.description"
                    defaultMessage="Please enter your email address. You will receive a link to create a new password via email."
                  />
                </p>
                <div className="form-group">
                  <label>
                    <FormattedMessage id="common.email" defaultMessage="Email" />
                  </label>
                  <input
                    type="text"
                    name="email"
                    onChange={setInputTagonChange}
                    value={formState.email}
                    className="form-control"
                  />
                </div>

                <button type="submit" className="btn btn-danger btn-bg btn-block mt-5 mb-4">
                  <FormattedMessage id="forgot.send" defaultMessage="SEND" />
                </button>
              </form>
              <p className="text-center mt-5 forgetpass-here">
                <FormattedMessage
                  id="common.register_call"
                  defaultMessage="Don't have an account?"
                />{' '}
                <Link to="register">
                  <FormattedMessage id="common.register_link" defaultMessage="Sign Up Here" />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const EnhancedForgotPassword = withAuth0WebAuth(ForgotPassword)

export default EnhancedForgotPassword
