import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import { isBrowser } from 'react-device-detect'

import config from '../api/config'
import UserPositionMarker from './UserPositionMarker'
import GoogleMapMarker from './GoogleMapMarker'
import markerCompany from '../assets/images/company.png'
import markerIndividual from '../assets/images/individual.png'

export const DEFAULT_ZOOM = 15

function GoogleMapContainer(props) {
  const {
    position,
    className,
    userLocation,
    setPositionFromMap,
    profiles,
    hoveredProfile,
    selectedProfile,
    setSelectedMarker,
    setHoveredMarker,
    clearHoveredMarker,
    setBounds,
    sideOpen,
    zoom,
    setZoom,
    clearSelection,
    boundsFromAutocomplete,
    defaultLocation
  } = props
  const { latitude, longitude } = position
  const { latitude: uLatitude, longitude: uLongitude } = userLocation
  const { latitude: dLatitude, longitude: dLongitude } = defaultLocation
  const [mapInstance, setMapInstance] = useState(null)
  const [mapApi, setMapApi] = useState(null)

  function onMapApiLoaded({ map, maps }) {
    setMapInstance(map)
    setMapApi(maps)
  }

  function onMarkerClick(_, childProps) {
    const { profile } = childProps
    setSelectedMarker(profile)
  }

  function onMouseEnterMarker(_, childProps) {
    const { profile } = childProps
    setHoveredMarker(profile)
  }

  function onMouseLeaveMarker() {
    clearHoveredMarker()
  }

  function clearAllSelection() {
    clearHoveredMarker()
    clearSelection()
  }

  function onMapChange(data) {
    const {
      center: { lat, lng },
      zoom,
      bounds
    } = data
    if (lat !== 0 && lng !== 0) {
      setPositionFromMap({ latitude: lat, longitude: lng })
      setBounds(bounds)
      setZoom(zoom)
    }
  }

  useEffect(() => {
    if (mapApi != null && mapInstance != null) {
      mapInstance.panTo(new mapApi.LatLng(latitude, longitude))
    }
    // eslint-disable-next-line
  }, [latitude, longitude])

  useEffect(() => {
    if (mapApi != null && mapInstance != null) {
      mapInstance.setZoom(zoom)
    }
    // eslint-disable-next-line
  }, [zoom])

  useEffect(() => {
    if (mapApi != null && mapInstance != null && boundsFromAutocomplete) {
      mapInstance.fitBounds(boundsFromAutocomplete)
    }
    // eslint-disable-next-line
  }, [boundsFromAutocomplete])

  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
    // eslint-disable-next-line
  }, [sideOpen])

  return (
    <div className={className}>
      {latitude !== 0 && longitude !== 0 && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.map_key }}
          defaultCenter={{ lat: dLatitude, lng: dLongitude }}
          defaultZoom={zoom || DEFAULT_ZOOM}
          onChange={onMapChange}
          onChildClick={onMarkerClick}
          onChildMouseEnter={onMouseEnterMarker}
          onChildMouseLeave={onMouseLeaveMarker}
          onClick={clearAllSelection}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={onMapApiLoaded}
          options={{
            clickableIcons: false,
            fullscreenControl: false,
            zoomControl: isBrowser
          }}
        >
          {uLatitude !== 0 && uLongitude !== 0 && (
            <UserPositionMarker lat={uLatitude} lng={uLongitude} />
          )}
          {profiles.map(profile => {
            const hovered =
              hoveredProfile &&
              (hoveredProfile.companyid === profile.companyid &&
                hoveredProfile.__type === profile.__type)
            const selected =
              selectedProfile &&
              (selectedProfile.companyid === profile.companyid &&
                selectedProfile.__type === profile.__type)
            return (
              <GoogleMapMarker
                key={`${profile.companyname}${profile.companyid}`}
                lat={profile.companylatitude}
                lng={profile.companylongitude}
                name={profile.companyname}
                profile={profile}
                focused={hovered || selected}
                icon={profile.__type === 'CompanyProfile' ? markerCompany : markerIndividual}
              />
            )
          })}
        </GoogleMapReact>
      )}
    </div>
  )
}

export default GoogleMapContainer
