import React from 'react'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'

import DefaultAside from './DefaultAside'
import ProfileAside from './ProfileAside'

function HomeAside(props) {
  const {
    profiles,
    keyword,
    setKeyword,
    setPosition,
    triggerSearch,
    onItemClick,
    onItemUnselected,
    onItemMouseIn,
    onItemMouseOut,
    hoveredProfile,
    selectedProfile,
    history,
    selectionConfirmed,
    profileKindFilter,
    setProfileKindFilter,
    authenticated,
    sideOpen,
    setSideOpen,
    userId,
    setDefaultZoom,
    setBoundsFromAutocomplete,
    address,
    setAddress
  } = props

  function toggleSidebar() {
    setSideOpen(!sideOpen)
  }

  return (
    <div className="aside-bg">
      {(isMobile && selectedProfile && selectionConfirmed) || (!isMobile && selectedProfile) ? (
        <ProfileAside
          history={history}
          profile={selectedProfile}
          authenticated={authenticated}
          userId={userId}
          clearSelectedProfile={onItemUnselected}
        />
      ) : (
        <DefaultAside
          address={address}
          setAddress={setAddress}
          authenticated={authenticated}
          toggleSidebar={toggleSidebar}
          sideOpen={sideOpen}
          triggerSearch={triggerSearch}
          setDefaultZoom={setDefaultZoom}
          keyword={keyword}
          setKeyword={setKeyword}
          selectedProfile={selectedProfile}
          profileKindFilter={profileKindFilter}
          setProfileKindFilter={setProfileKindFilter}
          profiles={profiles}
          hoveredProfile={hoveredProfile}
          onItemClick={onItemClick}
          onItemMouseIn={onItemMouseIn}
          onItemMouseOut={onItemMouseOut}
          setPosition={setPosition}
          setBoundsFromAutocomplete={setBoundsFromAutocomplete}
        />
      )}
    </div>
  )
}

function withRedux(Component) {
  return connect(
    ({ auth }) => ({
      authenticated: auth.authenticated,
      userId: auth.profile.id
    }),
    null
  )(Component)
}

const EnhancedHomeAside = withRedux(HomeAside)

export default EnhancedHomeAside
