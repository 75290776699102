import React, { useState } from 'react'
import { Modal, Carousel } from 'react-bootstrap'

function ImageCarousel(props) {
  const { hide, images, defaultActiveIndex } = props
  const [index, setIndex] = useState(0)
  const [direction, setDirection] = useState(null)

  function handleSelect(selectedIndex, e) {
    setIndex(selectedIndex)
    setDirection(e.direction)
  }

  return (
    <Modal
      size="lg"
      show={defaultActiveIndex !== undefined}
      className="carousel-popup"
      onHide={hide}
    >
      <Modal.Header closeButton className="modal-bg"></Modal.Header>
      <Modal.Body className="carousel-modal-body">
        <Carousel
          className="carousel-images"
          activeIndex={index}
          direction={direction}
          onSelect={handleSelect}
          defaultActiveIndex={defaultActiveIndex}
        >
          {images.map(item => (
            <Carousel.Item className="carousel-item" key={item.id}>
              <div className="carousel-image-container">
                <img src={item.url} alt={`Previous work ${item.id}`} />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  )
}

export default ImageCarousel
