import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import companyIcon from '../assets/images/company_pic.jpg'
import userIcon from '../assets/images/smile.jpg'

function ProfileModal(props) {
  const { profile, history, showCarousel } = props
  const [open, setOpen] = useState(false)
  const defaultAvatar = profile.__type === 'CompanyProfile' ? companyIcon : userIcon
  const withHttp = url => (!/^https?:\/\//i.test(url) ? `http://${url}` : url)

  function show() {
    setOpen(true)
  }

  function hide() {
    setOpen(false)
  }

  function sendMessage(e) {
    e.preventDefault()
    history.push('message', {
      id: profile.id ? profile.id : profile.companyid,
      __type: profile.__type
    })
  }

  return (
    <>
      <Button variant="default" className="more-btn" onClick={show}>
        <FormattedMessage id="common.more" defaultMessage="More" />
      </Button>

      <Modal show={open} className="profile-popup" onHide={hide}>
        <Modal.Header closeButton className="modal-bg"></Modal.Header>
        <Modal.Body className="my-3">
          <div className="row">
            <div className="col-md-4">
              <div className="model-left-sec mb-3">
                <div className="d-flex align-items-center">
                  <div className="modal-img">
                    <img
                      src={profile.profile_image === '' ? defaultAvatar : profile.profile_image}
                      className="img-fluid"
                      alt="userpic"
                    />
                  </div>
                  <div className="modal-img-text">
                    <h6>{profile.companyname}</h6>
                    {profile.distance_status ? (
                      <h6>
                        <FormattedMessage
                          id="common.distance"
                          defaultMessage="{distance} km"
                          values={{ distance: Math.round(profile.companydistance * 10) / 10 }}
                        />
                      </h6>
                    ) : null}
                  </div>
                </div>
                <div className="clr"></div>
                <p className="photographer-text">{profile.description}</p>
                {(profile.street_status || profile.city_status || profile.postcode_status) && (
                  <div className="pb-2 d-flex align-items-start">
                    <div>
                      <i className="fa fa-globe"></i>
                    </div>
                    <div className="pl-2 photographer-name">
                      {profile.street_status && <div>{profile.street.toLowerCase()}</div>}
                      {(profile.city_status || profile.postcode_status) && (
                        <div>
                          {profile.postcode_status && profile.postcode}
                          <span>&nbsp;</span>
                          {profile.city_status && profile.city}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {profile.activity != null &&
                  profile.activity.phone_status &&
                  profile.activity.phone !== '' && (
                    <div className="pb-2 d-flex align-items-start">
                      <div>
                        <i className="fa fa-phone"></i>
                      </div>
                      <div className="pl-2 photographer-name">
                        <a href={`tel:${profile.activity.phone}`}>{profile.activity.phone}</a>
                      </div>
                    </div>
                  )}
                {profile.activity != null &&
                  profile.activity.website_status &&
                  profile.activity.website !== '' && (
                    <div className="pb-2 d-flex align-items-start">
                      <div>
                        <i className="fa fa-link"></i>
                      </div>
                      <div className="pl-2 photographer-name">
                        <a
                          href={withHttp(profile.activity.website)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {profile.activity.website}
                        </a>
                      </div>
                    </div>
                  )}
                {profile.legalentitytype_status && profile.legalentitytype && (
                  <div className="pb-2 d-flex align-items-start">
                    <div>
                      <i className="fa fa-building"></i>
                    </div>
                    <div className="pl-2 photographer-name">{profile.legalentitytype}</div>
                  </div>
                )}
                {profile.legal_identifier_status &&
                  profile.legal_identifier &&
                  profile.legal_identifier !== '' && (
                    <div className="pb-2 d-flex align-items-start">
                      <div>
                        <i className="fa fa-file-contract"></i>
                      </div>
                      <div className="pl-2 photographer-name">
                        <FormattedMessage
                          id="profile.legal_identifier_display"
                          defaultMessage="Siret n° {legal_identifier}"
                          values={{
                            identifier: profile.legal_identifier
                          }}
                        />
                      </div>
                    </div>
                  )}
                {profile.activity != null && (
                  <p className="photographer-text">{profile.activity.description}</p>
                )}
              </div>
              <div className="clr"></div>
              <div className="model-left-btn">
                <div className="calling-btns meassage-btn pt-2">
                  <a href="#/" onClick={sendMessage}>
                    <i className="far fa-envelope mr-3 mx-2"></i>{' '}
                    <FormattedMessage id="profile.send_message" defaultMessage="Send a Message" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-8 mt-3">
              <div className="model-right-side">
                <h5 className="mb-3">Previous Work</h5>
                <div className="">
                  {profile.other_images.length > 0 ? (
                    <div className="d-flex flex-wrap justify-content-between">
                      {profile.other_images.map((item, index) => {
                        return (
                          <div className="col-md-4 previous-shots" key={index}>
                            <div className="model-right-profile-pics">
                              <img
                                src={item.url}
                                className="img-fluid"
                                onClick={() => showCarousel(index)}
                                alt="app-six"
                              />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  ) : (
                    <div>
                      <FormattedMessage
                        id="profile.no_previous_work"
                        defaultMessage="No previous work for this profile"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ProfileModal
