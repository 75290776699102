import { AUTH_USER, UNAUTH_USER, AUTH_ERROR, START_APP_WITHOUT_USER, RESET_MAP_INFO } from './types'
import api from '../api/index'

export const signUserIn = data => async dispatch => {
  const res = await api.auth.signUserInCall(data)
  if (res.status === 200) {
    const { phone_number, profile_pic, id, name } = res.data.data
    localStorage.setItem('auth_jwt_token', data.token)
    localStorage.setItem('email', data.email)
    localStorage.setItem('phonenumber', phone_number)
    localStorage.setItem('profile_pic', profile_pic)
    localStorage.setItem('id', id)
    localStorage.setItem('name', name)
    dispatch({ type: AUTH_USER, payload: res.data.data })
  } else {
    dispatch({ type: AUTH_ERROR, payload: res.data.message })
  }
}

export const signUserOut = () => dispatch => {
  dispatch({ type: UNAUTH_USER })
  dispatch({ type: RESET_MAP_INFO })
  localStorage.removeItem('auth_jwt_token')
  localStorage.removeItem('email')
  localStorage.removeItem('phonenumber')
  localStorage.removeItem('profile_pic')
  localStorage.removeItem('id')
  localStorage.removeItem('name')
}

export const startApp = () => dispatch => {
  dispatch({ type: START_APP_WITHOUT_USER })
}
