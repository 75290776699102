import React from 'react'
import Loadable from 'react-loadable'

import RequireAuth from './hoc/requireAuth'

function Loading() {
  return <div>Loading...</div>
}

const Home = Loadable({
  loader: () => import('./views/pages/Home'),
  loading: Loading
})

const CompanyIndex = Loadable({
  loader: () => import('./views/Company/CompanyIndex'),
  loading: Loading
})

const UpdateCompany = Loadable({
  loader: () => import('./views/Company/UpdateCompany'),
  loading: Loading
})

const CreateUserProfile = Loadable({
  loader: () => import('./views/User/CreateUserProfile'),
  loading: Loading
})

const Message = Loadable({
  loader: () => import('./views/Company/SendMessage'),
  loading: Loading
})

const AskForOwnership = Loadable({
  loader: () => import('./views/Company/AskForOwnership'),
  loading: Loading
})

const routes = [
  { path: '/home', exact: true, name: 'Home', component: Home },
  {
    path: '/update-company/:id',
    exact: true,
    name: 'Update Company',
    component: RequireAuth(UpdateCompany)
  },
  {
    path: '/update-individual/:id',
    exact: true,
    name: 'Update Individual',
    component: RequireAuth(CreateUserProfile)
  },
  {
    path: '/company-index',
    exact: true,
    name: 'Company Index',
    component: RequireAuth(CompanyIndex)
  },
  { path: '/message', exact: true, name: 'Message', component: Message },
  { path: '/ownership', exact: true, name: 'AskForOwnership', component: AskForOwnership }
]

export default routes
