import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import config from '../../api/config'
import MaterialTitlePanel from './MaterialTitlePanel'
import userpic from './../../../src/assets/images/smile.jpg'
import cross from './../../../src/assets/images/cross.png'
import cross_black from './../../../src/assets/images/cross_black.png'
import companypic from './../../../src/assets/images/company_icon.svg'
import { withAuth0WebAuth } from '../../context/auth0'
import { signUserOut } from '../../actions/auth'
import LanguageSwitcher from '../../components/LanguageSwitcher'

const styles = {
  sidebar: {
    width: 370,
    height: '100vh',
    backgroundColor: '#cc0605'
  },
  sidebarLink: {
    display: 'block',
    padding: '16px 0px',
    color: '#757575',
    textDecoration: 'none'
  },
  divider: {
    margin: '8px 0',
    height: 1,
    backgroundColor: '#757575'
  },
  content: {
    padding: '16px',
    height: '100%',
    backgroundColor: '#cc0605'
  }
}

function SidebarContent(props) {
  const { signUserOut, webAuth, moveToPage, profile, onSetOpen } = props
  const style = props.style ? { ...styles.sidebar, ...props.style } : styles.sidebar

  function logout(e) {
    e.preventDefault()
    webAuth.logout({
      returnTo: config.appUrl
    })
    signUserOut()
  }

  return (
    <MaterialTitlePanel style={style}>
      <div style={styles.content}>
        <div className="sidebar-content mt-5">
          <div className="sidebar-brand text-right mt-3">
            <div id="close-sidebar" className="cross_btn" onClick={() => onSetOpen(false)}>
              <img
                src={cross}
                className="img-fluid d-none d-sm-block d-md-block d-lg-block d-xl-block"
                alt="cross"
              />
              <img
                src={cross_black}
                className="img-fluid cross_black d-block d-sm-none d-md-none d-lg-none d-xl-none"
                alt="cross_black"
              />
            </div>
          </div>
          <div className="sidebar-header text-center">
            {props.authenticated && (
              <>
                <div
                  className="user-pic"
                  onClick={() => {
                    const url = '/update-individual/' + profile.id
                    moveToPage(url)
                  }}
                >
                  <img
                    src={profile.profile_pic ? profile.profile_pic : userpic}
                    className="img-fluid rounded-circle"
                    alt="userpic"
                  />
                </div>
                <div className="user-info">
                  <h6 className="user-name my-2">{profile.name}</h6>
                </div>
              </>
            )}
          </div>
          <div className="border-bottom mt-4 d-block d-sm-none d-md-none d-xl-none"></div>
          <div className="sidebar-menu mt-3">
            <ul>
              {props.authenticated && (
                <li className="sidebar-dropdown my-2">
                  <a
                    href="#/"
                    onClick={() => moveToPage('/company-index')}
                    style={styles.sidebarLink}
                  >
                    <img src={companypic} className="img-fluid mr-2" alt="companypic" />{' '}
                    <FormattedMessage id="company.my_companies" defaultMessage="My Companies" />
                  </a>
                </li>
              )}
              <li className="sidebar-dropdown my-2">
                <a
                  href={process.env.REACT_APP_LYDIA_URL}
                  style={styles.sidebarLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-coins help"></i>{' '}
                  <FormattedMessage id="common.support_us" defaultMessage="Support Us" />
                </a>
              </li>
              {props.authenticated && (
                <li className="sidebar-dropdown my-2">
                  <a href="#/" style={styles.sidebarLink} onClick={logout}>
                    <i className="fas fa-sign-out-alt logout"></i>{' '}
                    <FormattedMessage id="common.logout" defaultMessage="Logout" />
                  </a>
                </li>
              )}
            </ul>
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </MaterialTitlePanel>
  )
}

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated
})

function withRedux(Component) {
  return connect(
    mapStateToProps,
    { signUserOut }
  )(Component)
}

const EnhancedSidebarContent = withRedux(withAuth0WebAuth(SidebarContent))

export default EnhancedSidebarContent
