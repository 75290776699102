import React, { Component } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import loginimage from './../../assets/images/login.png'

export default class ControlledCarousel extends Component {
  constructor(props, context) {
    super(props, context)

    this.handleSelect = this.handleSelect.bind(this)

    this.state = {
      index: 0,
      direction: null
    }
  }

  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
      direction: e.direction
    })
  }

  render() {
    const { index, direction } = this.state

    return (
      <Carousel
        id="carouselExampleIndicators"
        className="carousel slide carousel-img"
        activeIndex={index}
        direction={direction}
        onSelect={this.handleSelect}
      >
        <Carousel.Item>
          <img className="d-block w-100" src={loginimage} alt="" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={loginimage} alt="" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={loginimage} alt="" />
        </Carousel.Item>
      </Carousel>
    )
  }
}
