import { BACKUP_MAP_INFO, RESET_MAP_INFO } from '../actions/types'

const initialState = {
  keyword: undefined,
  position: undefined,
  zoom: undefined,
  address: undefined,
  defaultPosition: undefined,
  bounds: undefined
}

export default function(state = initialState, action) {
  switch (action.type) {
    case BACKUP_MAP_INFO:
      const { keyword, position, zoom, address, defaultPosition, bounds } = action.payload
      return {
        ...state,
        keyword,
        position,
        zoom,
        address,
        defaultPosition,
        bounds
      }
    case RESET_MAP_INFO:
      return initialState
    default:
      return state
  }
}
