import { combineReducers } from 'redux'

import auth from './auth'
import user from './user'
import map from './map'

const rootReducer = combineReducers({
  auth,
  user,
  map
})

export default rootReducer
