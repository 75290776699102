import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import Sidebar from 'react-sidebar'
import { FormattedMessage } from 'react-intl'

import MaterialTitlePanel from './../../views/Widgets/MaterialTitlePanel'
import SidebarContent from '././../../views/Widgets/SidebarContent'
import routes from '../../routes'
import userIcon from '../../assets/images/smile.jpg'
import logo from './../../assets/images/logo.png'
import menu_icon from './../../assets/images/menu.png'
import Home from '../../views/pages/Home'

const styles = {
  contentHeaderMenuLink: {
    textDecoration: 'none',
    color: '#343a40',
    padding: 8
  },
  content: {
    padding: '16px'
  }
}

class DefaultLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      docked: false,
      open: false,
      transitions: true,
      touch: true,
      shadow: true,
      pullRight: false,
      touchHandleWidth: 20,
      dragToggleDistance: 30
    }

    this.renderPropCheckbox = this.renderPropCheckbox.bind(this)
    this.renderPropNumber = this.renderPropNumber.bind(this)
    this.onSetOpen = this.onSetOpen.bind(this)
    this.menuButtonClick = this.menuButtonClick.bind(this)
    this.goBack = this.goBack.bind(this)
    this.moveToPage = this.moveToPage.bind(this)
  }

  componentDidMount() {
    this.setState({
      address: this.props.locationSearched
    })
  }

  onSetOpen(open) {
    this.setState({ open })
  }

  menuButtonClick(ev) {
    ev.preventDefault()
    this.onSetOpen(!this.state.open)
  }

  renderPropCheckbox(prop) {
    const toggleMethod = ev => {
      const newState = {}
      newState[prop] = ev.target.checked
      this.setState(newState)
    }

    return (
      <p key={prop}>
        <label htmlFor={prop}>
          <input type="checkbox" onChange={toggleMethod} checked={this.state[prop]} id={prop} />
          {prop}
        </label>
      </p>
    )
  }

  goBack(e) {
    e.preventDefault()
    this.props.history.goBack()
  }

  renderPropNumber(prop) {
    const setMethod = ev => {
      const newState = {}
      newState[prop] = parseInt(ev.target.value, 10)
      this.setState(newState)
    }

    return (
      <p key={prop}>
        {prop} <input type="number" onChange={setMethod} value={this.state[prop]} />
      </p>
    )
  }

  moveToPage(page) {
    this.onSetOpen(false)
    const { history } = this.props
    history.push(page)
  }

  render() {
    const sidebar = (
      <SidebarContent
        onSetOpen={this.onSetOpen}
        moveToPage={this.moveToPage}
        profile={this.props.profile}
      />
    )
    const { pathname } = this.props.location
    const showBackArrow = [
      '/ownership',
      '/message',
      '/company-index',
      '/update-individual',
      '/update-company'
    ].some(item => {
      return pathname.startsWith(item)
    })

    const contentHeader = (
      <div className="header-container d-flex flex-row text-black justify-content-between align-items-center">
        <div className="header-left ml-2">
          {pathname === '/home' && (
            <a onClick={this.menuButtonClick} href="#/" style={styles.contentHeaderMenuLink}>
              <img src={menu_icon} alt="humburger" className="menu_icon" />
            </a>
          )}
          {showBackArrow && (
            <div className="goBack_button" onClick={this.goBack}>
              <i className="fas fa-arrow-left"></i>
            </div>
          )}
        </div>
        <div>
          {pathname !== '/message' && (
            <img
              onClick={() => {
                const { history } = this.props
                history.push('/')
              }}
              src={logo}
              alt="humburger"
              className="logo_image_fluid  sign-logo-img"
            />
          )}
        </div>
        <div className="header-right">
          {!this.props.authenticated && (
            <button
              type="button"
              className="btn btn-link btn-link-header mr-3"
              onClick={e => {
                e.preventDefault()
                const { history } = this.props
                history.push('signin')
              }}
            >
              <FormattedMessage id="common.login" defaultMessage="Log In" />
            </button>
          )}
          {this.props.authenticated && (
            <button
              type="button"
              className="btn btn-danger mr-2 pull-right profile-button"
              onClick={e => {
                e.preventDefault()
                const { history } = this.props
                var url = '/update-individual/' + this.props.profile.id
                history.push(url)
              }}
            >
              <img
                src={
                  this.props.profile.profile_pic === '' ? userIcon : this.props.profile.profile_pic
                }
                className="img-fluid"
                alt=""
              />
            </button>
          )}
        </div>
      </div>
    )

    const sidebarProps = {
      sidebar,
      docked: this.state.docked,
      sidebarClassName: 'custom-sidebar-class',
      contentId: 'custom-sidebar-content-id',
      open: this.state.open,
      touch: this.state.touch,
      shadow: this.state.shadow,
      pullRight: this.state.pullRight,
      touchHandleWidth: this.state.touchHandleWidth,
      dragToggleDistance: this.state.dragToggleDistance,
      transitions: this.state.transitions,
      onSetOpen: this.onSetOpen,
      moveToPage: this.moveToPage,
      profile: this.props.profile
    }

    return (
      <div
        className={
          pathname !== '/message'
            ? 'container wrapper-container layout'
            : 'container wrapper-container layout_message'
        }
      >
        {pathname === '/introduction' ? null : (
          <Sidebar {...sidebarProps}>
            <MaterialTitlePanel
              title={contentHeader}
              authenticated={this.props.authenticated}
              profile={this.props.profile}
            />
          </Sidebar>
        )}
        <Switch>
          {true ? (
            routes.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => <route.component {...props} />}
                />
              ) : null
            })
          ) : (
            <Route path="/" name="Home" component={Home} />
          )}
          {this.props.authenticated ? (
            <Redirect exact from="/" to="/home" />
          ) : (
            <Redirect exact from="/" to={'/home'} />
          )}
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    profile: state.auth.profile
  }
}
export default connect(mapStateToProps)(DefaultLayout)
