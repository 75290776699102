import axios from 'axios'
import config from './config'
import { configs1, configs } from './settings'

const ROOT_URL = config.url

axios.defaults.baseURL = ROOT_URL
axios.defaults.headers.post['Content-Type'] = 'application/json'

export default {
  // authentification calls
  auth: {
    signUserInCall: async data => {
      const { token, ...endpointData } = data
      try {
        return await axios.post('api/auth/login', endpointData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
      } catch (error) {
        return error.response
      }
    }
  },

  // ressources calls
  ressource: {
    getActivitiesCall: async userKind => {
      try {
        return await axios.get(`api/data/activity/${userKind}`)
      } catch (error) {
        return error.response
      }
    },
    getLegalEntitiesCall: async () => {
      try {
        return await axios.get('api/data/legalentity')
      } catch (error) {
        return error.response
      }
    },
    deleteCompanyCall: async companyId => {
      try {
        return await axios.delete(`api/data/company/${companyId}`, configs())
      } catch (error) {
        return error.response
      }
    },
    locationCall: async payloads => {
      try {
        return await axios.post('api/data/location', payloads)
      } catch (error) {
        return error.response
      }
    }
  },

  profile: {
    getProfileByIdCall: async (id, profileKind) => {
      try {
        return await axios.get(`api/profile/${id}/${profileKind}`, configs())
      } catch (error) {
        return error.response
      }
    },
    updateOrCreateProfileCall: async payloads => {
      try {
        return await axios.post('api/profile', payloads, configs1())
      } catch (error) {
        return error.response
      }
    },
    getUserCompanies: async payloads => {
      try {
        return await axios.get(`api/data/company`, configs())
      } catch (error) {
        return error.response
      }
    },
    sendMessageCall: async (profileKind, payloads) => {
      try {
        return await axios.post(`api/profile/message/${profileKind}`, payloads)
      } catch (error) {
        return error.response
      }
    },
    askForOwnership: async payload => {
      return await axios.post(`api/profile/ownerrequest`, payload, configs())
    },
    deleteUserImage: async imageId => {
      return await axios.delete(`api/profile/userimage/${imageId}`, configs())
    },
    deleteCompanyImage: async ({ imageId, companyId }) => {
      return await axios.delete(`api/profile/companyimage/${imageId}/${companyId}`, configs())
    }
  },

  search: {
    searchResultCall: async payloads => {
      try {
        return await axios.post(`api/search`, payloads, configs())
      } catch (error) {
        return error.response
      }
    }
  }
}
