import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import ProfileModal from '../components/ProfileModal'
import ImageCarousel from '../components/ImageCarousel'
import companyIcon from '../assets/images/company_pic.jpg'
import userIcon from '../assets/images/smile.jpg'

function ProfileAside(props) {
  const { history, profile, clearSelectedProfile, authenticated, userId } = props
  const [defaultCarouselIndex, setDefaultCarouselIndex] = useState(undefined)
  const intl = useIntl()
  const defaultAvatar = profile.__type === 'CompanyProfile' ? companyIcon : userIcon
  const isOwner = profile.owner_id != null && userId === profile.owner_id
  const withHttp = url => (!/^https?:\/\//i.test(url) ? `http://${url}` : url)

  function goToMessagePopup(e) {
    e.preventDefault()
    history.push('message', {
      id: profile.id ? profile.id : profile.companyid,
      __type: profile.__type
    })
  }

  function goToOwnershipPopup(e) {
    e.preventDefault()
    if (authenticated && !isOwner && profile.__type === 'CompanyProfile') {
      history.push('ownership', {
        profileId: profile.companyid
      })
    }
  }

  function showCarousel(defaultIndex) {
    setDefaultCarouselIndex(defaultIndex)
  }

  function hideCarousel() {
    setDefaultCarouselIndex(undefined)
  }

  useEffect(() => {
    document.body.classList.add('prevent-scroll')
    document.querySelector('html').classList.add('prevent-scroll')
    return () => {
      document.querySelector('html').classList.remove('prevent-scroll')
      document.body.classList.remove('prevent-scroll')
    }
  })

  return (
    <aside className="aside-photo-profile">
      <div>
        <img
          src={profile.profile_image === '' ? defaultAvatar : profile.profile_image}
          className="img-fluid"
          alt="smile"
        />
      </div>
      <div className="aside-person-detail">
        <div>
          <div className="arrow-icon" onClick={clearSelectedProfile}>
            <button type="button" className="btn btn-outline-dark">
              <i className="fas fa-arrow-left"></i>
            </button>
          </div>
          <div className="d-flex justify-content-between align-items-center photography-heading pb-4 pt-2">
            <h5>
              {profile.activity_status && profile.activity != null
                ? profile.activity.translations.find(t => t.lang === intl.locale)
                  ? profile.activity.translations.find(t => t.lang === intl.locale).value
                  : profile.activity.name
                : ''}
            </h5>
            <ProfileModal history={history} profile={profile} showCarousel={showCarousel} />
            <ImageCarousel
              defaultActiveIndex={defaultCarouselIndex}
              images={profile.other_images}
              hide={hideCarousel}
            />
          </div>

          <div className="pb-2 d-flex align-items-center">
            <div className="photographer-img">
              <img
                src={profile.profile_image === '' ? defaultAvatar : profile.profile_image}
                className="img-fluid"
                alt="userpic"
              />
            </div>
            <h6 className="photographer-name pt-1">{profile.companyname}</h6>
          </div>
          <div className="calling-btns pt-2 pb-2">
            <a href="#/" onClick={goToMessagePopup}>
              <i className="far fa-envelope"></i>{' '}
              <FormattedMessage id="profile.send_message" defaultMessage="Send a message" />
            </a>
          </div>
          {authenticated && !isOwner && profile.__type === 'CompanyProfile' && (
            <div className="calling-btns pt-2 pb-2">
              <a href="#/" onClick={goToOwnershipPopup}>
                <FormattedMessage id="profile.claim_company" defaultMessage="This is my company!" />
              </a>
            </div>
          )}
          {(profile.street_status || profile.city_status || profile.postcode_status) && (
            <div className="pb-2 d-flex align-items-start">
              <div>
                <i className="fa fa-globe"></i>
              </div>
              <div className="pl-2 pt-1 photographer-name">
                {profile.street_status && <div>{profile.street.toLowerCase()}</div>}
                {(profile.city_status || profile.postcode_status) && (
                  <div>
                    {profile.postcode_status && profile.postcode}
                    <span>&nbsp;</span>
                    {profile.city_status && profile.city}
                  </div>
                )}
              </div>
            </div>
          )}
          {profile.activity != null &&
            profile.activity.phone_status &&
            profile.activity.phone !== '' && (
              <div className="pb-2 d-flex align-items-start">
                <div>
                  <i className="fa fa-phone"></i>
                </div>
                <a href={`tel:${profile.activity.phone}`} className="pl-2 pt-1 photographer-name">
                  {profile.activity.phone}
                </a>
              </div>
            )}
          {profile.activity != null &&
            profile.activity.website_status &&
            profile.activity.website !== '' && (
              <div className="pb-2 d-flex align-items-start">
                <div>
                  <i className="fa fa-link"></i>
                </div>
                <div className="pl-2 pt-1 photographer-name">
                  <a
                    href={withHttp(profile.activity.website)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {profile.activity.website}
                  </a>
                </div>
              </div>
            )}
          {profile.legalentitytype_status && profile.legalentitytype && (
            <div className="pb-2 d-flex align-items-start">
              <div>
                <i className="fa fa-building"></i>
              </div>
              <div className="pl-2 pt-1 photographer-name">{profile.legalentitytype}</div>
            </div>
          )}
          {profile.legal_identifier_status &&
            profile.legal_identifier &&
            profile.legal_identifier !== '' && (
              <div className="pb-2 d-flex align-items-start">
                <div>
                  <i className="fa fa-file-contract"></i>
                </div>
                <div className="pl-2 pt-1 photographer-name">
                  {' '}
                  <FormattedMessage
                    id="profile.legal_identifier_display"
                    defaultMessage="Siret n° {legal_identifier}"
                    values={{
                      identifier: profile.legal_identifier
                    }}
                  />
                </div>
              </div>
            )}
          {profile.activity != null && (
            <p className="photographer-text">{profile.activity.description}</p>
          )}
          <div className="clr"></div>
          <div className="previous-work-container">
            <h6 className="Previous-work mb-2">Previous Work</h6>
            <div className="d-flex flex-wrap justify-content-between">
              {profile.other_images.map((item, index) => (
                <div key={index} className="previous-shots" onClick={() => showCarousel(index)}>
                  <img src={item.url} className="img-fluid" alt="tour-img4" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </aside>
  )
}

export default ProfileAside
