import React from 'react'
import { isMobile } from 'react-device-detect'

import companyIcon from '../assets/images/company_pic.jpg'
import userIcon from '../assets/images/smile.jpg'

function ProfileBox(props) {
  const { item, hovered, selectMarker, highlightMarker, clearHighlighted } = props
  const defaultAvatar = item.__type === 'CompanyProfile' ? companyIcon : userIcon

  return (
    <div
      className={`aside-profile d-flex ${hovered ? 'active-profile' : ''}`}
      onClick={() => selectMarker(item)}
      onMouseOver={() => !isMobile && highlightMarker(item)}
      onMouseOut={() => !isMobile && clearHighlighted()}
    >
      <div className="profile-img">
        <img src={item.profile_image === '' ? defaultAvatar : item.profile_image} alt="userpic" />
      </div>
      <div className="profile-img-content">
        <h6 className="mb-1">{item.companyname}</h6>
        {item.distance_status ? (
          <h6 className="profile-img-content-h6">
            {Math.round(item.companydistance * 100) / 100} km
          </h6>
        ) : null}
        {item.__type === 'CompanyProfile' && (
          <div className="profile-pro">
            <span className="badge badge-secondary">PRO</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProfileBox
