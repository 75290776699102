import React from 'react'

import markerCurrentLocation from '../assets/images/current_location.png'

function UserPositionMarker() {
  return (
    <div className="custom-marker-location">
      <img src={markerCurrentLocation} alt="Current location" className="marker-pin" />
    </div>
  )
}

export default UserPositionMarker
