import React from 'react'
import PropTypes from 'prop-types'

const styles = {
  root: {
    fontFamily:
      '"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
    fontWeight: 300
  },
  header: {
    backgroundColor: 'white',
    fontSize: '1.5em',
    boxShadow: 'rgba(12, 12, 12, 0.16) 0px 6px 29px -2px',
    zIndex: '999',
    position: 'relative'
  },
  headerNotLoggedIn: {
    backgroundColor: 'white',
    fontSize: '1.5em',
    boxShadow: 'rgba(12, 12, 12, 0.16) 0px 6px 29px -2px',
    zIndex: '999',
    position: 'relative'
  }
}

const MaterialTitlePanel = props => {
  const rootStyle = props.style ? { ...styles.root, ...props.style } : styles.root

  return (
    <div style={rootStyle}>
      <div style={props.authenticated ? styles.header : styles.headerNotLoggedIn}>
        {props.title}
      </div>
      {props.children}
    </div>
  )
}

MaterialTitlePanel.propTypes = {
  style: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.object
}

export default MaterialTitlePanel
