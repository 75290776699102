//authentification
export const AUTH_USER = 'AUTH_USER'
export const UNAUTH_USER = 'UNAUTH_USER'
export const AUTH_ERROR = 'AUTH_ERROR'
export const START_APP_WITHOUT_USER = 'START_APP_WITHOUT_USER'

//user
export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const UPDATE_USER_PROFILE_GOOD = 'UPDATE_USER_PROFILE_GOOD'
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL'
export const USER_LIST = 'USER_LIST'
export const USER_DELETE = 'USER_DELETE'
export const USER_CREATE = 'USER_CREATE'
export const DASHBOARD = 'DASHBOARD'

//Wallet Dispatcher
export const WALLET_CREATE = 'WALLET_CREATE'
export const WALLET_INDEX = 'WALLET_INDEX'
export const WALLET_UPDATE = 'WALLET_UPDATE'
export const WALLET_VIEW = 'WALLET_VIEW'
export const WALLET_DELETE = 'WALLET_DELETE'
export const WALLET_USER_LIST = 'WALLET_USER_LIST'

//Comapany Dispatcher
export const COMPANY_CREATE = 'COMPANY_CREATE'
export const SEND_MESSAGE = 'SEND_MESSAGE'
export const SEARCH_RESULT = 'SEARCH_RESULT'
export const GET_COMPANIES = 'GET_COMPANIES'
export const COMPANY_LISTING = 'COMPANY_LISTING'
export const NEAR_BY = 'NEAR_BY'
export const FETCH_COORDINATES = 'FETCH_COORDINATES'
export const FETCH_KEYWORDS = 'FETCH_KEYWORDS'

//UI
export const HIGHLIGHT_MARKER = 'HIGHLIGHT_MARKER'
export const CLEAR_HIGHLIGHTED = 'CLEAR_HIGHLIGHT'
export const SELECT_MARKER = 'SELECT_MARKER'
export const CLEAR_SELECTED = 'CLEAR_SELECTED'
export const SET_SEARCH_LOCATION = 'SET_SEARCH_LOCATION'
export const SET_SEARCH_KEYWORD = 'SET_KEYWORD'
export const SET_MAP = 'SET_MAP'
export const SET_USER_LOCATION = 'SET_USER_LOCATION'
export const FORCE_UPDATE_SEARCH = 'FORCE_UPDATE_SEARCH'

// MAP
export const BACKUP_MAP_INFO = 'BACKUP_MAP_INFO'
export const RESET_MAP_INFO = 'RESET_MAP_INFO'
