const config = {
  prod: {
    url: 'https://catchup-api.herokuapp.com',
    map_key: 'AIzaSyB0on3FJhzWS_QzbC9_YhQHTWxovNExyqk',
    appUrl: 'https://albanet.co',
    auth0Audience: 'https://api.albanet.co',
    auth0Domain: 'catchup-application.eu.auth0.com',
    auth0ClientID: '7sCODyb3XuAbRGDOxIJokKXgOANLky6j',
    auth0realm: 'ProdDatabase'
  },
  local: {
    url: 'http://localhost:8000',
    map_key: 'AIzaSyB0on3FJhzWS_QzbC9_YhQHTWxovNExyqk',
    appUrl: 'http://localhost:3000',
    auth0Audience: 'https://api.dev.albanet.co',
    auth0Domain: 'catchup-application.eu.auth0.com',
    auth0ClientID: 'Ut3N3IanerPoKT24fTjzf2L1j64c1p84',
    auth0realm: 'TestDatabase'
  },
  dev: {
    url: 'https://catchup-api-staging.herokuapp.com/',
    map_key: 'AIzaSyB0on3FJhzWS_QzbC9_YhQHTWxovNExyqk',
    appUrl: 'https://nervous-lumiere-399bab.netlify.app',
    auth0Audience: 'https://api.dev.albanet.co',
    auth0Domain: 'catchup-application.eu.auth0.com',
    auth0ClientID: 'Ut3N3IanerPoKT24fTjzf2L1j64c1p84',
    auth0realm: 'TestDatabase'
  }
}

const env =
  process.env.REACT_APP_HOST_ENV === 'production'
    ? 'prod'
    : process.env.REACT_APP_HOST_ENV === 'development'
    ? 'dev'
    : 'local'

export default config[env]
