import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { backupMap } from '../../actions/map'
import api from '../../api'
import HomeAside from '../../components/HomeAside'
import MapCenter from '../../components/MapCenter'
import MobileSearchResult from '../../components/MobileSearchResult'
import GoogleMapContainer, { DEFAULT_ZOOM } from '../../components/GoogleMapContainer'
import { ToastInfo } from '../../actions/toaster'
import { isMobile } from 'react-device-detect'

const DEFAULT_LOCATION = {
  longitude: 2.3522,
  latitude: 48.8566
}

function Home(props) {
  const {
    history,
    authenticated,
    keyword: _keyword,
    position: _position,
    zoom: _zoom,
    address: _address,
    defaultPosition: _defaultPosition,
    bounds: _bounds,
    backupMap
  } = props
  const [keyword, setKeyword] = useState(_keyword || '')
  const [address, setAddress] = useState(_address || '')
  const [sideOpen, setSideOpen] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState(undefined)
  const [hoveredProfile, setHoveredProfile] = useState(undefined)
  const [selectionConfirmed, setSelectedConfirmed] = useState(false) // Usefull for mobile
  const [mapBounds, setBounds] = useState(
    _bounds || {
      sw: { lat: 0, lng: 0 },
      ne: { lat: 0, lng: 0 }
    }
  )
  const [currentMapPosition, setCurrentMapPosition] = useState(
    _position || { latitude: 0, longitude: 0 }
  )
  const [boundsFromAutocomplete, setBoundsFromAutocomplete] = useState(undefined)
  const [defaultLocation, setDefaultLocation] = useState(
    _defaultPosition || { latitude: 0, longitude: 0 }
  )
  const [profileKindFilter, setProfileKindFilter] = useState({ company: true, user: true })
  const [currentZoom, setZoom] = useState(_zoom || DEFAULT_ZOOM)
  const [profileData, setProfileData] = useState([])
  const filteredProfileData = profileData.filter(profile => {
    const { company, user } = profileKindFilter
    return (
      (company && profile.__type === 'CompanyProfile') || (user && profile.__type === 'UserProfile')
    )
  })

  async function updateProfiles(updateZoom) {
    const { longitude, latitude } = defaultLocation
    const { sw, ne } = mapBounds

    try {
      const basePayload = {
        longitude,
        latitude,
        neLng: ne.lng,
        neLat: ne.lat,
        swLng: sw.lng,
        swLat: sw.lat
      }

      const payload = keyword.length === 0 ? basePayload : { ...basePayload, keyword }
      const {
        data: { data: profiles }
      } = await api.ressource.locationCall(payload)
      setProfileData(profiles)
      if (updateZoom) {
        setZoom(DEFAULT_ZOOM)
      }
    } catch (e) {
      console.error(e)
    }
  }

  function restoreDefaultLocation() {
    setAddress('')
    setCurrentMapPosition(defaultLocation)
    setDefaultZoom()
  }

  function setDefaultZoom() {
    setZoom(DEFAULT_ZOOM)
  }

  function triggerSearch() {
    const updateZoom = boundsFromAutocomplete === undefined
    updateProfiles(updateZoom)
  }

  function onProfileSelection(profile) {
    setSelectedProfile(profile)
    setHoveredProfile(undefined)
  }

  function setPositionFromMap(position) {
    setCurrentMapPosition(position)
  }

  function clearSelection() {
    setSelectedConfirmed(false)
    setSelectedProfile(undefined)
  }

  function confirmSelection() {
    setSelectedConfirmed(true)
  }

  useEffect(() => {
    // Get current position
    if (_position === undefined) {
      // new data
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            setCurrentMapPosition(coords)
            setDefaultLocation(coords)
          },
          error => {
            console.warn(error)
            ToastInfo('Use default location')
            setCurrentMapPosition(DEFAULT_LOCATION)
            setDefaultLocation(DEFAULT_LOCATION)
          },
          { enableHighAccuracy: true, timeout: 4000 }
        )
      } else {
        setCurrentMapPosition(DEFAULT_LOCATION)
        setDefaultLocation(DEFAULT_LOCATION)
      }
    } else {
      const { longitude } = currentMapPosition
      const { nw } = mapBounds
      if (longitude !== 0 && nw.lat !== 0) {
        updateProfiles(false)
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const { longitude } = currentMapPosition
    const { nw } = mapBounds
    if (longitude !== 0 && nw.lat !== 0) {
      updateProfiles()
    }
    // eslint-disable-next-line
  }, [mapBounds])

  useEffect(() => {
    if (authenticated) {
      document.body.classList.add('userloggedin')
    } else {
      document.body.classList.remove('userloggedin')
    }
  }, [authenticated])

  useEffect(() => {
    const { longitude } = currentMapPosition
    const { nw } = mapBounds
    if (keyword.length === 0 && longitude !== 0 && nw.lat !== 0) {
      updateProfiles()
    }
    // eslint-disable-next-line
  }, [keyword])

  useEffect(() => {
    backupMap({
      keyword,
      position: currentMapPosition,
      defaultPosition: defaultLocation,
      address,
      zoom: currentZoom,
      bounds: mapBounds
    })
    // eslint-disable-next-line
  }, [keyword, defaultLocation, currentMapPosition, address, currentZoom, mapBounds])

  return (
    <div>
      <div className="home_container">
        <div className="home_container--sidebar">
          <HomeAside
            profiles={filteredProfileData}
            setKeyword={setKeyword}
            setPosition={setCurrentMapPosition}
            triggerSearch={triggerSearch}
            onItemMouseIn={setHoveredProfile}
            onItemMouseOut={() => setHoveredProfile(undefined)}
            onItemClick={onProfileSelection}
            onItemUnselected={clearSelection}
            hoveredProfile={hoveredProfile}
            selectedProfile={selectedProfile}
            keyword={keyword}
            history={history}
            profileKindFilter={profileKindFilter}
            setProfileKindFilter={setProfileKindFilter}
            sideOpen={sideOpen}
            setDefaultZoom={setDefaultZoom}
            setSideOpen={setSideOpen}
            confirmSelection={confirmSelection}
            selectionConfirmed={selectionConfirmed}
            setBoundsFromAutocomplete={setBoundsFromAutocomplete}
            address={address}
            setAddress={setAddress}
          />
        </div>
        <div className="home_container--map">
          <MapCenter onClick={restoreDefaultLocation} />
          <GoogleMapContainer
            className="map-container"
            position={currentMapPosition}
            userLocation={defaultLocation}
            defaultLocation={_position === undefined ? defaultLocation : currentMapPosition}
            setPositionFromMap={setPositionFromMap}
            profiles={filteredProfileData}
            hoveredProfile={hoveredProfile}
            selectedProfile={selectedProfile}
            setSelectedMarker={setSelectedProfile}
            setHoveredMarker={setHoveredProfile}
            sideOpen={sideOpen}
            clearHoveredMarker={() => setHoveredProfile(undefined)}
            setBounds={setBounds}
            zoom={currentZoom}
            setZoom={setZoom}
            clearSelection={clearSelection}
            boundsFromAutocomplete={boundsFromAutocomplete}
          />
          {isMobile && !selectionConfirmed && (
            <MobileSearchResult
              profiles={profileData}
              onItemMouseIn={setHoveredProfile}
              onItemMouseOut={() => setHoveredProfile(undefined)}
              onItemClick={onProfileSelection}
              hoveredProfile={hoveredProfile}
              selectedProfile={selectedProfile}
              confirmSelection={confirmSelection}
              selectionConfirmed={selectionConfirmed}
            />
          )}
        </div>
      </div>
    </div>
  )
}

function withRedux(Component) {
  return connect(
    ({ auth, map }) => ({
      authenticated: auth.authenticated,
      keyword: map.keyword,
      position: map.position,
      zoom: map.zoom,
      address: map.address,
      defaultPosition: map.defaultPosition,
      bounds: map.bounds
    }),
    { backupMap }
  )(Component)
}

const EnhancedHome = withRedux(Home)

export default EnhancedHome
