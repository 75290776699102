import {
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE_GOOD,
  UPDATE_USER_PROFILE_FAIL,
  USER_CREATE,
  SEND_MESSAGE,
  SEARCH_RESULT,
  GET_COMPANIES
} from '../actions/types'

let INITIAL_STATE = {
  updateProfileFailMsg: '',
  profile: null,
  searchResult: {},
  companies: [],
  status: null
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USER_PROFILE:
      return { ...state, profile: action.payload }
    case UPDATE_USER_PROFILE_GOOD:
      return { ...state, updateProfileFailMsg: '' }
    case UPDATE_USER_PROFILE_FAIL:
      return { ...state, updateProfileFailMsg: 'Incorrect Password' }
    case USER_CREATE:
      return { ...state, status: action.payload }
    case SEND_MESSAGE:
      return { ...state, status: action.payload }
    case SEARCH_RESULT:
      return { ...state, searchResult: action.payload }
    case GET_COMPANIES:
      return { ...state, companies: action.payload }
    default:
      return state
  }
}
