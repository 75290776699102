import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { withAuth0WebAuth } from '../context/auth0'
import { signUserIn, startApp } from '../actions/auth'

function AuthGuard(props) {
  const { children, webAuth, authenticated, signUserIn, startApp } = props

  useEffect(() => {
    webAuth.checkSession(
      {
        responseType: 'token id_token'
      },
      (err, authResult) => {
        if (err == null) {
          const { accessToken } = authResult
          webAuth.client.userInfo(accessToken, (err, user) => {
            if (err == null) {
              const { name, email } = user
              signUserIn({
                name,
                email,
                token: accessToken
              })
            } else {
              startApp()
            }
          })
        } else {
          startApp()
        }
      }
    )
    // eslint-disable-next-line
  }, [])

  return <>{authenticated != null && children}</>
}

function withRedux(Component) {
  return connect(
    ({ auth }) => ({
      authenticated: auth.authenticated
    }),
    { signUserIn, startApp }
  )(Component)
}

const EnhancedAuthGuard = withRedux(withAuth0WebAuth(AuthGuard))

export default EnhancedAuthGuard
