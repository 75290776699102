import React from 'react'
import center from '../assets/images/target.svg'

function MapCenter(props) {
  const { onClick } = props

  return (
    <button className="center-map" onClick={onClick} type="button">
      <img src={center} alt="Show current location" />
    </button>
  )
}

export default MapCenter
