import React, { useContext } from 'react'

import { TranslationContext, LANGUAGES } from './TranslationProvider'

export default function LanguageSwitcher() {
  const { switchLocale, locale } = useContext(TranslationContext)

  return (
    <div className="language-selector">
      <select onChange={e => switchLocale(e.target.value)} value={locale}>
        {LANGUAGES.map(lang => (
          <option value={lang.code} key={lang.code}>
            {lang.name}
          </option>
        ))}
      </select>
    </div>
  )
}
