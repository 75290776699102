import React from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { FormattedMessage } from 'react-intl'

function GoogleAutocomplete(props) {
  const { setPosition, address, setAddress } = props
  function handleInputChange(address) {
    setAddress(address)
  }

  function handleSelect(address) {
    geocodeByAddress(address)
      .then(async results => {
        const formatted = results[0].formatted_address
        const data = await getLatLng(results[0])
        const { lat, lng } = data

        setAddress(formatted)
        setPosition({
          latitude: lat,
          longitude: lng,
          bounds: results[0].geometry.bounds,
          types: results[0].types
        })
      })
      .catch(error => console.error(error))
  }

  return (
    <div className="search-input mt-2">
      <PlacesAutocomplete value={address} onChange={handleInputChange} onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <input
              {...getInputProps({
                placeholder: 'Location…',
                className: 'location-search-input form-control'
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && (
                <div className="location-loaders">
                  <div>
                    <FormattedMessage id="common.loading" defaultMessage="Loading…" />
                  </div>
                </div>
              )}
              {suggestions.map(suggestion => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                const style = suggestion.active
                  ? {
                      color: '#fff',
                      backgroundColor: '#FF2D56',
                      padding: '10px',
                      cursor: 'pointer'
                    }
                  : {
                      color: '#fff',
                      padding: '10px',
                      backgroundColor: '#F52D55',
                      cursor: 'pointer'
                    }
                return (
                  <div
                    key={suggestion.id}
                    {...getSuggestionItemProps(suggestion, { className, style })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </PlacesAutocomplete>
    </div>
  )
}

export default GoogleAutocomplete
