import React, { useEffect, useRef } from 'react'

import ProfileBox from './ProfileBox'

function MobileSearchResult(props) {
  const {
    profiles,
    onItemClick,
    onItemMouseIn,
    onItemMouseOut,
    selectedProfile,
    hoveredProfile,
    confirmSelection
  } = props
  const resultContainerRef = useRef(null)

  function onProfileClick(item) {
    onItemClick(item)
    confirmSelection()
  }

  useEffect(() => {
    const { current } = resultContainerRef
    if (selectedProfile && current) {
      // scroll to element
      const index = profiles.findIndex(
        item =>
          selectedProfile.companyid === item.companyid && selectedProfile.__type === item.__type
      )

      if (index !== -1) {
        current.scrollTo({
          left: 210 * index,
          behavior: 'smooth'
        })
      }
    }
  })

  return (
    <div className="mobile-search-container" ref={resultContainerRef}>
      <div className="mobile-search-items-container">
        {profiles.map(item => {
          const hovered =
            hoveredProfile &&
            (hoveredProfile.companyid === item.companyid && hoveredProfile.__type === item.__type)
          const selected =
            selectedProfile &&
            (selectedProfile.companyid === item.companyid && selectedProfile.__type === item.__type)

          return (
            <ProfileBox
              item={item}
              key={`${item.companyid}_${item.__type}`}
              hovered={hovered || selected}
              selectMarker={onProfileClick}
              highlightMarker={onItemMouseIn}
              clearHighlighted={onItemMouseOut}
            />
          )
        })}
      </div>
    </div>
  )
}

export default MobileSearchResult
