import React from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'reactstrap'
import { FormattedMessage } from 'react-intl'

import ControlledCarousel from '../Widgets/ControlledCarousel'

function Signin(props) {
  const { history } = props

  return (
    <div className="container wrapper-container">
      <div className="row">
        <div className="col-md-6 signup-bg login-img d-none d-md-flex align-items-center">
          <div className="signup-detail login-slider">
            <h1>
              <FormattedMessage id="login.image_title" defaultMessage="Ease of use" />
            </h1>
            <ControlledCarousel />
          </div>
        </div>
        <div className="col-md-6 signup-form d-flex align-items-center">
          <div className="signup-here login-here">
            <Form>
              <h2 className="mb-5">
                <i
                  onClick={() => history.goBack()}
                  className="fas fa-arrow-left d-inline-block d-md-none mr-3"
                ></i>
                <FormattedMessage id="login.welcome" defaultMessage="Hi, Welcome!" />
              </h2>

              <button
                type="button"
                className="btn btn-login btn-danger btn-bg btn-block mt-5 mb-4"
                onClick={() => history.push('/register')}
              >
                <FormattedMessage id="login.create_account" defaultMessage="Create account" />
              </button>
              <button
                type="button"
                className="btn btn-login btn-danger btn-bg btn-block mt-5 mb-4"
                onClick={() => history.push('/login')}
              >
                <FormattedMessage
                  id="login.have_account"
                  defaultMessage="You already have an account"
                />
              </button>
              <p className="text-center">
                <Link to="/home">
                  <FormattedMessage id="common.home" defaultMessage="Return Home" />
                </Link>
              </p>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signin
