import {
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  USER_LIST,
  COMPANY_CREATE,
  COMPANY_LISTING,
  START_APP_WITHOUT_USER
} from '../actions/types'

const initialState = {
  profile: {},
  error: null,
  authenticated: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        error: '',
        authenticated: true,
        profile: action.payload,
        status: action.payload
      }
    case UNAUTH_USER:
      return { ...state, profile: {}, authenticated: false }
    case START_APP_WITHOUT_USER:
      return { ...state, authenticated: false }
    case AUTH_ERROR:
      return { ...state, error: action.payload }
    case COMPANY_LISTING:
      return { ...state, companyDetails: action.payload }
    case USER_LIST:
      return { ...state, nearby: action.payload }
    case COMPANY_CREATE:
      return { ...state, status: action.payload }
    default:
      return state
  }
}
